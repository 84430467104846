.billing-software-development-services {
  &.firstSectionView {
    [data-title] {
      max-width: 700px;

      @include phone {
        max-width: 340px;
      }
    }

    [data-description] {
      max-width: 700px;

      @include phone {
        max-width: 340px;
      }
    }

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .cardList {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 32px;
    }

    .card svg {
      width: 64px;
    }

    .callToAction {
      background-color: $gray50;
      max-width: 994px;
      margin: 48px auto 0;
      border: unset;

      h3 {
        @include fontSize(24px, 130%);

        max-width: 535px;

        @include phone {
          max-width: 315px;
        }
      }

      @include phone {
        padding: 32px 16px;
      }
    }
  }

  &.secondSectionView {
    @extend .greySection;

    [data-description] {
      max-width: 750px;

      @include phone {
        max-width: 350px;
      }
    }

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .cardList {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 32px;

      & > div {
        margin: 0;
      }
    }

    .card {
      @extend .cardWithVerticalLine;
    }
  }

  &.thirdSectionView {
    @extend .greySection;

    .callToAction {
      margin-top: 48px;
      border: unset;

      h3 {
        @include fontSize(24px, 130%);

        max-width: 535px;
      }

      @include phone {
        padding: 32px 16px;
      }
    }
  }

  &.fourthSectionView {
    @extend .greySection;

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .card {
      @extend .cardWithTransperentBackground;

      flex-direction: row;
    }
  }

  &.fifthSectionView {
    background-color: $gray50;

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .cardList {
      @extend .threeColumnsCards;
    }

    .card {
      @include flex(column, center, center);

      padding: 24px;
      gap: 24px;

      * {
        margin: 0;
        padding: 0;
      }

      picture {
        aspect-ratio: 1;
        width: 276px;
      }

      h4 {
        @include fontSize(20px, 150%);

        color: $gray900;
        text-align: center;
      }

      h5 {
        @include fontSize(16px, 150%);

        color: $gray900;
        font-weight: 400;
        text-align: center;
      }

      p {
        @include fontSize(16px, 150%);

        margin: 8px 0;
        color: $gray400;
        font-weight: 400;
        text-align: center;
      }

      ul {
        @include flex(row, center, center, wrap);

        gap: 12px;
        list-style-type: none;

        li {
          padding: 12px 24px;
          background-color: $gray100;
          border-radius: 200px;

          & > p {
            @include fontSize(12px, 150%);

            margin: 0;
            color: $gray900;
          }
        }
      }
    }
  }
}
