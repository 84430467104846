.mobile-app-development-company {
  &.firstSectionView,
  &.thirdSectionView {
    @extend .greySection;
  }

  &.firstSectionView {
    [data-subtitle],
    [data-description] {
      max-width: 800px;
    }

    [data-title] {
      max-width: 500px;
    }
  }

  &.secondSectionView {
    .title {
      max-width: 700px;
    }

    @include below-tablet {
      .cards > div {
        display: block;
      }
    }
  }

  &.thirdSectionView {
    .title {
      max-width: 768px;
    }

    .callToAction h3 {
      max-width: 535px;
    }

    .card {
      @extend .cardWithVerticalLine;
    }
  }

  &.fourthSectionView {
    .title {
      max-width: 671px;
    }

    [data-title] {
      @include phone {
        max-width: 300px;
      }
    }
  }

  &.fifthSectionView {
    @extend .blackSection;

    h3 {
      color: $gray0;
    }
  }

  &.sixthSectionView {
    padding-bottom: 0;

    .contentWrapper {
      max-width: $wide-content-width;
    }

    [data-read-more] {
      display: block;
    }

    .card {
      @extend .cardWithFullSizeImage;

      height: 100%;
    }
  }
}
