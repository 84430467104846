@import "styles/utils/mixins";

.cardContainer {
  position: relative;
  border: solid 1px $gray100;
  border-radius: 5px;
  background: $gray0;
  padding: 32px;
}

.cardContainer.noBackground {
  padding: 0;
  background: none;
  border: none;
}

/* For Storybook */
.sb_cardWithOverlay {

  &:hover .overlay {
    display: block;
  }
}