.machine-learning-development-services {
  &.firstSectionView {
    @extend .greySection;

    .title {
      max-width: 750px;
    }
  }

  &.secondSectionView {
    .title {
      max-width: 700px;
    }

    .callToAction h3 {
      max-width: 525px;
    }

    [data-title] {
      @include phone {
        max-width: 290px;
      }
    }
  }

  &.thirdSectionView {
    padding-bottom: 0;

    .title {
      max-width: 745px;
    }

    .card {
      @extend .cardWithVerticalLine;
    }
  }
}
