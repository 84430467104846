.cardsSection.data-science-development-company {
  &.firstSectionView {
    @extend .greySection;
  }

  &.secondSectionView {
    [data-title] {
      max-width: 766px;
    }

    .callToAction {
      margin-top: 48px;
      padding: 80px 60px;
      background-color: $gray50;
      border: none;

      h3 {
        @include fontSize(24px, 130%);
      }

      @include tablet {
        margin-top: 60px;
        padding: 32px 16px;
      }
    }
  }

  &.secondSectionView,
  &.thirdSectionView,
  &.fourthSectionView {
    .card {
      flex-direction: column;

      svg {
        width: 64px;
      }

      @include below-tablet {
        align-items: center;

        * {
          align-items: center;
          text-align: center;
          margin-left: 0;
        }
      }
    }
  }

  &.fourthSectionView {
    @extend .greySection;

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .cardList {
      @extend .threeColumnsCards;
    }

    .callToAction {
      margin: 48px auto 0;
      padding: 80px 60px;
      background-color: $gray0;
      border: none;
      max-width: $default-content-width;

      h3 {
        @include fontSize(24px, 130%);

        max-width: 535px;
      }

      @include tablet {
        margin-top: 60px;
        padding: 32px 16px;
      }
    }
  }

  &.sixthSectionView {
    @extend .greySection;

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .card {
      @extend .cardWithFullSizeImage;
    }

    [data-read-more] {
      display: block;
    }

    .callToAction {
      margin-top: 48px;
      padding: 80px 60px;
      background-color: $gray0;
      border: none;

      h3 {
        @include fontSize(24px, 130%);
      }

      @include tablet {
        margin-top: 60px;
        padding: 32px 16px;
      }
    }
  }
}
