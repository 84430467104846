.book-a-call {
  &.firstSectionView {
    .contentWrapper {
      max-width: $wide-content-width;
    }

    .cardList {
      @extend .threeColumnsCards;
    }

    .callToAction {
      border: none;
      padding: 0;
      min-height: auto;

      button {
        margin: 0 auto;
      }
    }
  }

  &.secondSectionView {
    @extend .blackSection;

    [data-description] {
      max-width: 671px;
    }

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .cardList {
      grid-template-columns: repeat(5, auto);

      & > div {
        margin-left: 32px;

        @include below-tablet {
          flex: 1 1 auto;
        }
      }

      @include below-tablet {
        @include flex(row, center, flex-start, wrap);
      }
    }

    .card {
      * {
        text-align: center;
        align-items: center;
        color: $gray0;
      }

      h3 {
        @include fontSize(46px);

        color: $yellow400;
      }

      p {
        color: $gray0;
      }

      & > div {
        align-items: center;
      }
    }
  }

  &.thirdSectionView {
    padding-bottom: 0;

    .contentWrapper {
      max-width: $wide-content-width;
    }

    :global(.swiper-slide) {
      &:nth-child(1) {
        width: 194px;
      }

      &:nth-child(2) {
        width: 180px;
      }

      &:nth-child(3) {
        width: 252px;
      }

      &:nth-child(4) {
        width: 106px;
      }

      &:nth-child(5) {
        width: 202px;
      }

      &:nth-child(6) {
        width: 221px;
      }

      &:nth-child(7) {
        width: 211px;
      }
    }

    .card {
      width: 100%;

      picture {
        width: 100%;
        height: 140px;
      }
    }
  }

  &.fourthSectionView {
    @extend .greySection;

    :global(.swiper) > div:last-child {
      display: none;
    }

    :global(.swiper-slide) {
      width: 133px;
    }

    .card {
      width: 100%;

      picture {
        width: 100%;
        height: 140px;
      }
    }

    .contentWrapper {
      max-width: $wide-content-width;

      @include below-tablet {
        margin: 0 -40px;
      }
    }
  }
}
