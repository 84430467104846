@import 'styles/utils/mixins';
@import 'styles/utils/sizes';

@mixin appDevelopmentContainer {
  padding: 120px 40px;
  background-color: $white;
  margin-bottom: -1px;

  @include below-tablet {
    padding: 60px 16px;
  }
}

@mixin smallSectionWrapper {
  @include flex(column, center, stretch);

  max-width: $max-content-width-small-section;
  margin: 0 auto;
  position: relative;
}

@mixin defaultCard {
  position: relative;

  h3 {
    @include fontSize(24px, 150%);

    font-weight: 700;
    margin: 20px 0 0;

    @include below-tablet {
      @include fontSize(18px);

      margin-top: 16px;
    }
  }

  p {
    @include fontSize(16px, 180%);

    color: $silver-chalice;
    margin: 10px 0 0;

    @include below-tablet {
      @include fontSize(14px);
    }
  }

  @include below-tablet {
    @include flex(row);

    h3 {
      margin-top: 0;
    }
  }
}

@mixin cardWithShadow {
  background: $white;
  box-shadow: $card-bow-shadow;
  padding: 50px;
  display: block;
  border-radius: 5px;

  @include below-tablet {
    padding: 24px;

    h3 {
      margin-top: 16px;
    }
  }
}

@mixin overlay {
  @include flex(column, flex-start, center);

  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $white;
  padding: 105px 15px 15px;

  h3 {
    transform: scale(0.9);
    transition: transform 0.1s ease-in-out;
  }

  &:hover {
    opacity: 1;

    h3 {
      transform: none;
    }
  }
}

@mixin centeredIcons {
  :global(.swiper-wrapper) {
    align-items: center;
  }

  :global(.swiper-slide) {
    & > div,
    & {
      @include flex(row, center, center);

      flex: 1 1 auto;
      height: auto;
    }
  }
}

.cardsWithFullWidthImage {
  .cards {
    & > div {
      display: block;
    }

    @include below-tablet {
      grid-gap: 33px;
    }
  }

  .imageWrapper {
    width: 100%;
    height: auto;
    border-radius: 0;
    background: none;

    & > div {
      @include centeredBackgroundImage;

      width: 100%;
      padding-top: 85%;
      background-size: contain;

      @include tablet {
        padding-top: 72%;
      }
    }
  }

  .typeTitle {
    margin: 24px 0 10px;

    @include below-tablet {
      margin: 20px 0 10px;
      text-align: center;
    }
  }
}

.greySection {
  background: $alabaster;

  .callToAction {
    background: $white;
  }
}

.threeColumnsCards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @include below-tablet {
    grid-template-columns: 1fr;
  }
}

.twoColumnsCards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include below-tablet {
    grid-template-columns: 1fr;
  }
}

.textContent {
  @include flex(column, initial, center);
}
