.banking-software-development-company {
  &.firstSectionView {
    background-color: $gray50;

    :global(.swiper-slide) {
      height: 500px;
    }

    :global(.swiper-wrapper) {
      height: 500px !important;
    }

    .swiper {
      padding-bottom: 80px;
    }

    .contentWrapper {
      max-width: 1200px;
    }

    .navigation button {
      padding: 0;
    }

    .callToAction {
      max-width: 994px;
      height: 215px;
      margin: 48px auto 0;
      padding: 76.5px 60px;
      border: none;
      border-radius: 0;

      & > div {
        max-width: 535px;
      }

      @include below-tablet {
        height: 245px;
        margin-top: 60px;
        padding: 32px 42px;
      }
    }
  }

  &.secondSectionView {
    background-color: $gray50;

    :global(.swiper-slide) {
      height: 380px;

      @include below-tablet {
        height: 434px;
      }
    }

    .contentWrapper {
      max-width: 1200px;
    }

    .title {
      max-width: 807px;
    }

    .callToAction {
      max-width: 994px;
      height: 215px;
      margin: 48px auto 0;
      padding: 76.5px 60px;
      font-size: 24px;
      border: none;
      border-radius: 0;

      & > div {
        max-width: 535px;
      }

      @include below-tablet {
        margin-top: 60px;
        padding: 32px 42px;
      }
    }
  }

  &.thirdSectionView {
    .contentWrapper {
      max-width: $wide-content-width;
    }

    .cardList {
      @extend .threeColumnsCards;
    }

    .card {
      @include flex(column, center, center);

      padding: 24px;
      gap: 24px;

      * {
        margin: 0;
        padding: 0;
      }

      picture {
        aspect-ratio: 1;
        width: 276px;
      }

      h4 {
        @include fontSize(20px, 150%);

        color: $gray900;
        text-align: center;
      }

      h5 {
        @include fontSize(16px, 150%);

        color: $gray900;
        font-weight: 400;
        text-align: center;
      }

      p {
        @include fontSize(16px, 150%);

        margin: 8px 0;
        color: $gray400;
        font-weight: 400;
        text-align: center;
      }

      ul {
        @include flex(row, center, center, wrap);

        gap: 12px;
        list-style-type: none;

        li {
          padding: 12px 24px;
          background-color: $gray100;
          border-radius: 200px;

          & > p {
            @include fontSize(12px, 150%);

            margin: 0;
            color: $gray900;
          }
        }
      }
    }
  }
}
