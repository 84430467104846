.robotic-process-automation-services {
  &.firstSectionView {
    background-color: $gray0;

    .title h2 {
      max-width: 550px;
    }

    .card {
      border: none;
    }

    .callToAction {
      background-color: $gray50;
      border: none;
    }

    a {
      border-color: $gray900;

      &:hover {
        color: $yellow500;
        border-color: $yellow500;
      }
    }
  }

  &.thirdSectionView {
    .contentWrapper {
      max-width: $wide-content-width;
    }

    .cardList {
      @extend .threeColumnsCards;
      @include flex(row, center,stretch, wrap);

      & > * {
        flex: 0 1 30%;
      }
    }

    .card {
      @extend .cardWithFullSizeImage;
      @include flex(column, flex-start, flex-start);

      border: none;
      padding: 0;
      align-items: center;

      picture {
        width: 276px;
        height: 276px;
        margin: 0 auto 24px;
      }

      * {
        align-items: center;
        text-align: center;
      }
    }
  }
}
