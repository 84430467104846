.ai-mobile-cooking-app {
  [data-title] {
    @include fontSize(32px, 120%);

    font-weight: 800;

    @include below-tablet {
      @include fontSize(24px, 150%);
    }
  }

  [data-description] {
    color: $gray500;
  }

  &.firstSectionView {
    background-color: $tasteMatchMashroom;

    [data-title] {
      color: $gray0;
    }

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .cardList {
      @include flex(column);

      margin: 0;
      gap: 32px;

      & > div {
        margin: 0;
      }
    }

    .card {
      padding: 48px;
      gap: 32px;
      background-color: $gray0;
      box-sizing: border-box;
      border: 5px solid $gray900;
      border-radius: 40px;
      box-shadow: 8px 8px 0 0 $gray900;

      & > div > div {
        display: grid;
        grid-template-rows: repeat(4, auto);
        grid-template-columns: repeat(6, auto);
        gap: 16px;

        @include tablet {
          @include flex(column);
        }
      }

      picture {
        margin: 0 auto;
        aspect-ratio: 230/280;
        width: 230px;
      }

      h3 {
        @include fontSize(24px, 130%);

        margin: 0;
        color: $gray900;
        font-weight: 800;
        text-align: center;
      }

      h4 {
        @include fontSize(18px, 130%);

        margin: 8px 0;
        color: $gray900;
        font-weight: 800;
      }

      h5 {
        @include fontSize(16px, 130%);

        margin: 0;
        color: $gray500;
        font-weight: 400;
        text-align: center;
      }

      p {
        margin: 0;
        color: $gray500;
      }

      @include below-tablet {
        padding: 32px 16px;
      }
    }

    .card-1 {
      [grid-area='asset'] {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-column-end: 3;
      }

      [grid-area='name'] {
        grid-row-start: 3;
        grid-column-start: 1;
        grid-row-end: 4;
        grid-column-end: 3;
      }

      [grid-area='about'] {
        grid-row-start: 1;
        grid-column-start: 3;
        grid-row-end: 2;
        grid-column-end: 7;

        h4 {
          margin: 0 0 8px;
        }
      }

      [grid-area='goals'] {
        grid-row-start: 2;
        grid-column-start: 3;
        grid-row-end: 4;
        grid-column-end: 5;
      }

      [grid-area='challenges'] {
        grid-row-start: 2;
        grid-column-start: 5;
        grid-row-end: 4;
        grid-column-end: 7;
      }

      [grid-area='expectations'] {
        grid-row-start: 4;
        grid-column-start: 3;
        grid-row-end: 5;
        grid-column-end: 7;
      }
    }

    .card-2 {
      [grid-area='asset'] {
        grid-row-start: 1;
        grid-column-start: 5;
        grid-row-end: 3;
        grid-column-end: 7;
      }

      [grid-area='name'] {
        grid-row-start: 3;
        grid-column-start: 5;
        grid-row-end: 4;
        grid-column-end: 7;
      }

      [grid-area='about'] {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 2;
        grid-column-end: 5;

        h4 {
          margin: 0 0 8px;
        }
      }

      [grid-area='goals'] {
        grid-row-start: 2;
        grid-column-start: 1;
        grid-row-end: 4;
        grid-column-end: 3;
      }

      [grid-area='challenges'] {
        grid-row-start: 2;
        grid-column-start: 3;
        grid-row-end: 4;
        grid-column-end: 5;
      }

      [grid-area='expectations'] {
        grid-row-start: 4;
        grid-column-start: 1;
        grid-row-end: 5;
        grid-column-end: 5;
      }
    }
  }

  &.secondSectionView {
    background-color: $tasteMatchEgg;

    :global(.swiper) {
      overflow: visible;
    }

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .cardList {
      @include flex(row, space-between, initial, wrap);

      margin: 0;
      gap: 32px;

      & > div {
        flex: 0 0 31%;
        margin: 0;
      }
    }

    .card {
      @include flex(column, flex-start, flex-start);

      padding: 32px 28px;
      background-color: $gray0;
      border: 5px solid $gray900;
      border-radius: 40px;
      box-shadow: 8px 8px 0 0 $gray900;

      h4 {
        @include fontSize(24px, 150%);

        margin: 0 0 8px;
        font-weight: 800;
      }

      h3 {
        @include fontSize(20px, 40px);

        margin-bottom: 24px;
        width: 40px;
        height: 40px;
        color: $gray0;
        border-radius: 50%;
        border: 2px solid $gray900;
        box-shadow: 2px 2px 0 0 $gray900;
        font-weight: 900;
        text-align: center;
        text-shadow: 1px 2px $gray900;
        -webkit-text-stroke: 2px $gray900;
      }

      &-1 h3 {
        background-color: $tasteMatchEgg;
      }

      &-2 h3 {
        background-color: $tasteMatchCayenne;
      }

      &-3 h3 {
        background-color: $tasteMatchPepper;
      }

      &-4 h3 {
        background-color: $tasteMatchPlum;
      }

      &-5 h3 {
        background-color: $tasteMatchMashroom;
      }

      &-6 h3 {
        background-color: $tasteMatchMint;
      }
    }
  }
}
