.fintech-software-development-services {
  &.firstSectionView {
    @extend .greySection;

    .contentWrapper {
      max-width: $default-content-width;
      margin: 0 auto;
    }

    .callToAction h3 {
      max-width: 535px;
    }

    .card {
      @include flex(column, flex-start, flex-start);

      @include below-tablet {
        align-items: center;

        * {
          text-align: center;
          align-items: center;
        }
      }
    }
  }

  &.secondSectionView {
    @extend .greySection;

    .title {
      max-width: 750px;
    }

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .card {
      @include flex(column, flex-start, flex-start);
    }
  }

  &.thirdSectionView {
    @extend .blackSection;

    [data-swiper-button] {
      @include darkSliderButton;
    }

    .title {
      max-width: 671px;
    }

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .cardList {
      @extend .threeColumnsCards;
    }

    .card {
      flex-direction: column;

      @include below-tablet {
        align-items: center;

        * {
          align-items: center;
          text-align: center;
          margin-right: 0;
        }

        ul {
          @include flex(column, center, center);
        }
      }
    }

    button {
      color: $gray400;
    }
  }

  &.fourthSectionView {
    padding-bottom: 0;

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .cardList {
      @extend .threeColumnsCards;
    }

    .card {
      @include flex(column, center, center);

      padding: 24px;
      gap: 24px;

      * {
        margin: 0;
        padding: 0;
      }

      picture {
        aspect-ratio: 1;
        width: 276px;
      }

      h4 {
        @include fontSize(20px, 150%);

        color: $gray900;
        text-align: center;
      }

      h5 {
        @include fontSize(16px, 150%);

        color: $gray900;
        font-weight: 400;
        text-align: center;
      }

      p {
        @include fontSize(16px, 150%);

        margin: 8px 0;
        color: $gray400;
        font-weight: 400;
        text-align: center;
      }

      ul {
        @include flex(row, center, center, wrap);

        gap: 12px;
        list-style-type: none;

        li {
          padding: 12px 24px;
          background-color: $gray100;
          border-radius: 200px;

          & > p {
            @include fontSize(12px, 150%);

            margin: 0;
            color: $gray900;
          }
        }
      }
    }
  }
}
