.software-development-columbus,
.software-development-new-orleans,
.software-development-tulsa,
.software-development-miami,
.software-development-minneapolis,
.software-development-omaha,
.software-development-washington,
.software-development-oklahoma,
.software-development-nashville,
.software-development-raleigh {
  &.firstSectionView {
    background: $gray50;

    .title {
      max-width: 700px;
    }

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .callToAction {
      border: none;

      & > div {
        max-width: 535px;
      }
    }
  }

  &.secondSectionView {
    @extend .blackSection;

    .title {
      max-width: 750px;
    }

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .card {
      @include flex(column, flex-start, center);

      picture {
        width: 276px;
        height: 276px;
      }

      h3 {
        margin: 24px auto 8px;
      }

      * {
        text-align: center;
      }
    }
  }

  &.thirdSectionView {
    .contentWrapper {
      max-width: $wide-content-width;
    }

    .cardList {
      @extend .threeColumnsCards;
    }
  }
}
