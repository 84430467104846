.crowdfunding-platform {
  background: $crowdfundingPlatformPrimary;

  .title {
    color: $gray0;

    [data-title] {
      @include fontSize(14px);

      font-weight: 400;
    }
  }

  .contentWrapper {
    max-width: $wide-content-width;
  }

  .cardList {
    @extend .threeColumnsCards;
  }

  .card {
    background: $crowdfundingPlatformSecondary;
    border: none;
    border-radius: 0;

    h3 {
      @include fontSize(20px);

      color: $gray0;
      font-weight: 400;
    }

    p:first-child + p {
      margin-top: 15px;
    }

    p {
      @include fontSize(14px);

      color: $gray500;

      b {
        color: $gray0;
        font-weight: 400;
      }
    }
  }
}
