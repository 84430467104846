.payment-gateway-development-company {
  &.firstSectionView {
    :global(.swiper) {
      padding-bottom: 0;
    }

    [data-title],
    [data-description] {
      max-width: 680px;

      @include phone {
        max-width: 345px;
      }
    }

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .navigation button {
      padding: 0;
    }

    .card {
      svg {
        width: 64px;
        margin: 0 0 16px;
      }
    }

    @include below-tablet {
      padding-bottom: 60px;
    }
  }

  &.secondSectionView {
    @extend .greySection;

    [data-title] {
      max-width: 800px;

      @include phone {
        max-width: 345px;
      }
    }

    .contentWrapper {
      max-width: 994px;
    }

    .cardList {
      max-width: 994px;
      margin: 0;
      grid-gap: 32px;

      & > div {
        margin: 0;
      }
    }

    .card {
      @extend .cardWithVerticalLine;
    }

    .callToAction div {
      max-width: 535px;

      @include phone {
        max-width: 310px;
      }
    }

    @include below-tablet {
      padding-bottom: 60px;
    }
  }

  &.thirdSectionView {
    @extend .greySection;

    :global(.swiper-wrapper) {
      min-height: 450px;

      @include below-tablet {
        min-height: 450px;
      }
    }

    [data-title] {
      max-width: 720px;

      @include phone {
        max-width: 345px;
      }
    }

    [data-description] {
      max-width: 610px;

      @include phone {
        max-width: 340px;
      }
    }

    .callToAction div {
      max-width: 490px;

      @include phone {
        max-width: 310px;
      }
    }

    .card {
      @include flex(column, flex-start, stretch);

      min-height: 450px;

      * {
        align-self: center;
        text-align: center;
        margin: 0;
      }

      h3 {
        @include below-tablet {
          margin-bottom: 8px;
        }
      }

      picture {
        margin: 0 0 16px;
        width: 276px;
        height: 276px;
      }

      @include below-tablet {
        max-width: 450px;
        min-height: 350px;
      }

      @include phone {
        max-width: 350px;
        margin-bottom: 16px;
        min-height: unset;
      }
    }

    .navigation {
      margin-top: 24px;

      @include below-tablet {
        margin: 0;
      }
    }

    @include below-tablet {
      padding-bottom: 60px;
    }
  }

  &.fourthSectionView {
    @extend .greySection;

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .cardList {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &.fifthSectionView {
    padding-bottom: 60px;

    :global(.swiper-slide) {
      @include below-tablet {
        height: 134px !important;
      }
    }

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .card h3 {
      max-width: 470px;
    }

    .navigation {
      @include below-tablet {
        margin-top: 24px;
      }
    }

    @include phone {
      padding-bottom: 30px;
    }
  }

  &.sixthSectionView {
    padding-bottom: 0;

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .cardList {
      @extend .threeColumnsCards;
    }

    .card {
      @include flex(column, center, center);

      padding: 24px;
      gap: 24px;

      * {
        margin: 0;
        padding: 0;
      }

      picture {
        aspect-ratio: 1;
        width: 276px;
      }

      h4 {
        @include fontSize(20px, 150%);

        color: $gray900;
        text-align: center;
      }

      h5 {
        @include fontSize(16px, 150%);

        color: $gray900;
        font-weight: 400;
        text-align: center;
      }

      p {
        @include fontSize(16px, 150%);

        margin: 8px 0;
        color: $gray400;
        font-weight: 400;
        text-align: center;
      }

      ul {
        @include flex(row, center, center, wrap);

        gap: 12px;
        list-style-type: none;

        li {
          padding: 12px 24px;
          background-color: $gray100;
          border-radius: 200px;

          & > p {
            @include fontSize(12px, 150%);

            margin: 0;
            color: $gray900;
          }
        }
      }
    }
  }
}
