.cloud-based-app-development-services {
  &.firstSectionView {
    @extend .greySection;

    [data-description] {
      max-width: 760px;
    }

    padding-bottom: 0;
  }

  &.secondSectionView {
    [data-title] {
      max-width: 630px;
    }

    [data-description] {
      max-width: 660px;
    }

    .card {
      flex-direction: column;

      @include below-tablet {
        align-items: center;

        * {
          align-items: center;
          text-align: center;
          margin-left: 0;
        }
      }
    }
  }

  &.thirdSectionView {
    @extend .blackSection;

    [data-title] {
      max-width: 630px;
    }

    [data-description] {
      max-width: 610px;
    }

    [data-swiper-button] {
      @include darkSliderButton;
    }

    .card {
      @include flex(column, flex-start, flex-start);

      picture {
        width: 276px;
        height: 276px;
      }

      @include below-tablet {
        align-items: center;

        * {
          align-items: center;
          text-align: center;
        }
      }
    }
  }

  &.fourthSectionView {
    padding-bottom: 0;

    .card {
      flex-direction: column;

      @include below-tablet {
        align-items: center;

        * {
          align-items: center;
          text-align: center;
          margin-left: 0;
        }
      }
    }

    .callToAction h3 {
      max-width: 470px;
    }
  }
}
