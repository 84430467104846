.mvp-app-development-company {
  &.secondSectionView,
  &.thirdSectionView {
    @extend .greySection;
  }

  &.firstSectionView {
    .title {
      max-width: 595px;
    }

    .card {
      @include flex(column, flex-start, flex-start);
    }
  }

  &.thirdSectionView {
    [data-title] {
      max-width: 640px;
    }
  }

  &.fourthSectionView {
    padding-bottom: 0;

    .title {
      max-width: 580px;
    }

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .card {
      @extend .cardWithFullSizeImage;

      height: 100%;

      span {
        margin-top: auto;
      }
    }

    @include tablet {
      padding-bottom: 30px;
    }
  }
}
