.discovery-phase-services {
  &.firstSectionView {
    .title {
      max-width: 550px;
    }

    .card {
      flex-direction: column;

      @include tablet {
        align-items: center;

        * {
          text-align: center;
          align-items: center;
          margin-right: 0;
        }
      }
    }
  }

  &.secondSectionView {
    @extend .blackSection;

    [data-title] {
      max-width: 637px;
    }

    [data-description] {
      max-width: 640px;
    }

    .card {
      @include flex(column, flex-start, flex-start);

      @include below-tablet {
        align-items: center;

        * {
          text-align: center;
          align-items: center;
          margin-right: 0;
        }
      }
    }
  }

  &.thirdSectionView {
    @extend .greySection;

    .cardList {
      @include flex(row, center, flex-start, wrap);

      & > div {
        flex: 0 0 30%;

        @include phone {
          flex: none;
          margin: 0;
        }
      }

      @include phone {
        margin: 0;
        align-items: center;
        flex-direction: column;
      }
    }

    .card {
      @include flex(column, flex-start, stretch);

      max-width: 296px;
      height: 100%;

      * {
        align-self: center;
        text-align: center;
        margin: 0;
      }

      svg {
        margin: 0 0 16px;
      }

      @include phone {
        max-width: 350px;
        margin-bottom: 16px;
      }
    }
  }

  &.fourthSectionView {
    @extend .greySection;

    .title {
      max-width: 683px;
      margin: 0 auto 48px;
    }

    .card {
      @include tablet {
        flex-direction: column;
      }
    }
  }

  &.fifthSectionView {
    .title {
      max-width: 736px;
      margin: 0 auto 48px;
    }

    .card {
      @include flex(column, flex-start, center);

      picture {
        width: 276px;
        height: 276px;
      }

      * {
        width: 100%;
        text-align: center;
      }
    }
  }

  &.sixthSectionView {
    background-color: $gray50;

    .title {
      max-width: 756px;
    }

    [data-description] {
      &:first-child {
        @include fontSize(24px, 130%);

        margin: 24px 0 0;
        color: $gray900;
        font-weight: 700;
      }

      &:last-child {
        @include fontSize(16px, 150%);

        margin: 16px 0 0;
        color: $gray400;
      }
    }

    .contentWrapper {
      max-width: $wide-content-width;
    }
  }
}

.software-development-services-for-startups {
  &.thirdSectionView {
    background-color: $gray100;
  }

  &.fourthSectionView{
    background-color: $gray900;

    .title {
      color: $gray0;
    }

    .card {
      background-color: $gray900;
      border-color: $gray600;

      h3 {
        color:$gray0;
      }

      p {
        color: $white-alpha;
      }
    }
  }

  &.fifthSectionView {
    .title {
      max-width: 736px;
      margin: 0 auto 48px;
    }

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .cardList {
      @include flex(row, center, flex-start, wrap);

      & > div {
        flex: 0 0 30%;

        @include phone {
          flex: none;
          margin: 0;
        }
      }

      @include phone {
        margin: 0;
        align-items: center;
        flex-direction: column;
      }
    }

    .card {
      @include flex(column, flex-start, center);

      picture {
        width: 276px;
        height: 276px;

        img {
          width: 276px;
          height: 276px;
        }
      }

      * {
        width: 100%;
        text-align: center;
      }
    }
  }
}
