.cardWithFullSizeImage {
  padding: 24px;

  picture {
    align-self: stretch;
    margin: -24px -24px 0;
    height: 260px;
  }

  img {
    object-fit: cover;
  }

  h3 {
    margin-top: 16px;
  }
}

.blackSection {
  background: $gray900;

  .title,
  h3 {
    color: $gray0;
  }

  button {
    color: $gray400;
  }
}

@mixin cardCentered {
  @include flex(column, center, center);

  * {
    align-items: center;
    text-align: center;
  }
}

.cardWithVerticalLine {
  padding: 24px 24px 24px 42px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background: $yellow400;
    width: 2px;
    top: 24px;
    left: 24px;
    bottom: 24px;
  }
}

.cardsSection {
  @include appDevelopmentContainer;

  background: $gray0;

  .overlay {
    border-radius: 5px;

    & > * {
      @include flex(row, center, center);

      flex: 1 1 auto;
      border: none !important;
      padding: 0 24px;

      * {
        align-items: center;
        text-align: center;
      }
    }
  }

  .card {
    width: 100%;
    height: 100%;

    &.withOverlay {
      p {
        display: none;
      }

      &:hover .overlay {
        @include flex(row, center, center);

        p:not([data-read-more]) {
          display: block;
        }

        @include below-tablet {
          display: none;
        }
      }
    }
  }

  :global(.swiper-slide) {
    @include flex(row, center, stretch);

    height: auto;

    & > * {
      width: 100%;
    }
  }

  .callToAction {
    margin: 48px auto 0;
    padding: 40px 60px;
    max-width: $default-content-width;

    h3 {
      @include fontSize(24px);
    }

    @include below-tablet {
      margin-top: 60px;
    }
  }
}

.contentWrapper {
  max-width: $default-content-width;
  margin: 0 auto;
}

.cardList {
  @extend .twoColumnsCards;

  margin: 0 0 -32px -32px;

  & > * {
    @include flex(row, center, stretch);

    margin: 0 0 32px 32px;

    & > * {
      flex: 1 1 auto;
    }
  }
}

.cardWithTransperentBackground {
  background: transparent;
  border: none;
  padding: 0;
}
