.mobile-bank-application {
  .cardList {
    @extend .threeColumnsCards;
  }

  .card {
    @include flex(column, flex-start, center);

    padding: 24px 18px;

    [data-read-more] {
      display: none !important;
    }

    picture {
      width: 100%;
    }

    * {
      align-items: center;
      text-align: center;
    }

    p {
      display: none;
    }

    @include below-tablet {
      p {
        display: block;
      }
    }
  }
}
