.homepage {
  &.firstSectionView {
    background: $gray50;

    [data-title] {
      max-width: 625px;
      font-weight: 600;
    }

    [data-description] {
      @include fontSize(20px, 150%);

      max-width: 625px;
      color: $black-alpha;

      @include below-tablet {
        @include fontSize(16px, 150%);
      }
    }

    .contentWrapper {
      max-width: $wide-content-width;
    }

    .cardList {
      @include flex(row, center, stretch, wrap);

      margin: 0;
      gap: 16px;

      & > div {
        flex: 0 0 389px;
        margin: 0;
      }

      @include tablet {
        & > div {
          flex: 0 0 300px;
        }
      }

      @include below-tablet {
        @include flex(column, center, stretch);
      }
    }

    .card {
      @include flex(column, flex-start, flex-start);

      padding: 32px;
      gap: 24px;
      background-color: $gray0;
      border: none;
      border-radius: 16px;

      picture {
        width: 100%;
      }

      * {
        text-align: left;
      }

      h3 {
        @include fontSize(20px, 150%);

        font-weight: 600;
      }

      p {
        color: rgba($gray800, 0.64);
        max-width: 285px;
      }
    }
  }
}
